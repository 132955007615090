<template>
  <el-row :gutter="20" class="page-box task-list-page">
    <el-col>
      <Breadcrumb
        :home="false"
        :breadcrumbs="[
          {
            label: '产品管理',
            to: '/product',
          },
        ]"
      />
    </el-col>
    <el-col>
      <el-card class="grid-card" shadow="never">
        <SchemaTable
          ref="schematableref"
          :schema="schema"
          :model="dataModel"
          :search="search"
          :filters="filters"
          :queryConf="queryConf"
          :hasReset="hasReset"
          :hasAction="hasAction"
          :canDelete="false"
          :formConf="formConf"
        >
        <template #actionBtns="{ row }">
              <el-link type="primary" @click="deleteRow(row)">
                删除
              </el-link>
            </template>
        </SchemaTable>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./product.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import axios from "axios";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      hasAction: true,
      search: "",
      filters: ["name"],
      schema,
      hasReset: true,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.product}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          return item;
        },
        getApi: `${uris.product}${attachId}`,
        getMap(res) {
          // res.isEiot = true;
          return res;
        },
        deleteApi: `${uris.product}${attachId}`,
        createApi: uris.product,
        updateApi: `${uris.product}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
      formConf: {
        getServerOptions: this.getServerOptions.bind(this),
        productEnum: {},
      },
      productEnum: {}
    };
  },
  methods: {
     async getServerOptions(scenario) {
      const optionMd = new DataModel({
        getApi: "/api/v1/product/defined?type=4",
      });
      const res = await optionMd.get();
      let options = await this.optionsFormat(res.list);
      const productEnum = {}
      options.forEach(item=>{
        productEnum[item.value] = item.label ? item.label.split('(')[0] : '';
      })
      this.formConf.productEnum = productEnum;
      return options;
    },
    async optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) { 
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    async deleteRow(record) {
      this.modelCtx = record;
      this.$confirm("确认删除？删除产品，其关联订单、制程、工序等一并被删除。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          (async () => {
            try {
              this.dialogLoading = true;
              await this.dataModel.delete({ id: record.id });

              this.dialogLoading = false;
              this.$message.success("删除成功");

              this.$refs.schematableref.onSearch();
              this.dialogVisible = false;
            } catch (err) {
              this.dialogLoading = false;
              this.$message.error(err.message || "未知错误");
              console.error(err.response);
            }
          })();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>